
import { lazy, React } from "react";
import { createBrowserRouter } from "react-router-dom";

const AppContainer = lazy(() => import("./components/App/AppContainer"));
const Home = lazy(() => import("./pages/Home/Home"));
const Message = lazy(() => import("./pages/Message/Message"));
const Support = lazy(() => import("./pages/Support/Support"));
const PaymentsPage = lazy(() => import("./components/Finance/Payments"));
const Transaction = lazy(() => import("./pages/Transaction/Transaction"));
const Accounts = lazy(() => import("./pages/Accounts/Accounts"));
const Sadakka = lazy(() => import("./pages/Sadakka/Sadakka"))

/// SERVICES ///
const CardItem = lazy(() => import('./pages/Services/CardItem') )
const Piggy = lazy(() => import('./pages/Services/PiggyItem'))
const DepositItem = lazy(() => import('./pages/Services/DepositItem'))
const MortgageItem = lazy(() => import('./pages/Services/MortgageItem'))
const InstallmentItem = lazy(() => import('./pages/Services/InstallmentItem'))


// ADD SERVICES ///
const PiggyAdd = lazy(() => import('./pages/Services/ServicesCreate/PiggyAdd'))
const DepositAdd = lazy(() => import('./pages/Services/ServicesCreate/DepositAdd'))
const MortgageAdd = lazy(() => import('./pages/Services/ServicesCreate/MortgageAdd'))

// ACCOUNTS FORMS ///

const PiggyForm = lazy(() => import('./pages/Services/ServicesForm/PiggyForm'))
const DepositForm = lazy(() => import('./pages/Services/ServicesForm/DepositForm'))
const MortgageForm = lazy(() => import('./pages/Services/ServicesForm/MortgageForm'))


/// PROFILE

const Profile = lazy(() => import('./pages/Profile/Profile'))
const Replenishment = lazy(() => import('./pages/Replenishment/Replenishment'))



// PAYMENTS
const SadakkaItem = lazy(() => import("./pages/Sadakka/SadakkaItem"))
const ReplenishmentCard = lazy(() => import("./pages/Replenishment/ReplenishmentCard"))
const ReplenishmentAddCard = lazy(() => import("./pages/Replenishment/AddCard"))
const Withdraw = lazy(() => import("./pages/Withdraw/Withdraw"))
const Transfer = lazy(() => import("./pages/Transfer/Transfer"))
const TransferInternational = lazy(() => import("./pages/Transfer/TransferTypes/TransferInternational"))
const TransferRK = lazy(() => import("./pages/Transfer/TransferTypes/TransferRK"))
const TransferProportunity = lazy(() => import("./pages/Transfer/TransferTypes/TransferProportunity"))
const TransferSelf = lazy(() => import("./pages/Transfer/TransferTypes/TransferSelf"))

export const ROUTES = {
  WELCOME: "/",
  HOME: "/home",
  FINANCE: '/finance',
  SUPPORT: '/support',
  PAYMENTS: '/payments',
  TRANSACTION: '/transaction',
  ACCOUNTS: '/accounts',
  CARDITEM: '/carditem',
  PIGGY: '/piggyitem',
  DEPOSIT: '/deposititem',
  MORTGAGE: '/mortgageitem',
  INSTALLMENT: '/installmentitem',
  PIGGY_ADD: '/piggy_add',
  DEPOSIT_ADD: '/deposit_add',
  MORTGAGE_ADD: '/mortgage_add',
  // FORM //
  PIGGY_FORM: '/piggy_form',
  DEPOSIT_FORM: '/deposit_form',
  MORTGAGE_FORM: '/mortgage_form',

  //PROFILE
  PROFILE: '/profile',
  MESSAGE: '/message',
  SADAKKA: '/sadakka',
  SADAKKA_ITEM: '/sadaka_item',
  REPLENISHMENT: '/replenishment',
  REPLENISHMENT_CARD: '/replenishment_card',
  REPLENISHMENT_ADD_CARD: '/replenishment_add_card',
  WITHDRAW: '/withdraw',
  TRANSFER: '/Transfer',
  TRANSFER_INTERNATIONAL: '/transfer_international',
    TRANSFER_RK: '/transfer_rk',
    TRANSFER_PROPORTUNITY: '/transfer_proportunity',
    TRANSFER_SELF: '/transfer_self'
};

export const router = createBrowserRouter([
  {
    path: ROUTES.WELCOME,
    element: <AppContainer />,
  },
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.MESSAGE,
    element: <Message />,
  },
  {
    path: ROUTES.TRANSACTION,
    element: <Transaction />,
  },
  {
    path: ROUTES.SUPPORT,
    element: <Support />,
  },
  {
    path: ROUTES.PAYMENTS,
    element: <PaymentsPage/>
  },
  {
    path: ROUTES.ACCOUNTS,
    element: <Accounts/>
  },
  {
    path: ROUTES.CARDITEM,
    element: <CardItem/>
  },
  {
    path: ROUTES.PIGGY,
    element: <Piggy/>
  },
  {
    path: ROUTES.DEPOSIT,
    element: <DepositItem/>
  },
  {
    path: ROUTES.MORTGAGE,
    element: <MortgageItem/>
  },
  {
    path: ROUTES.INSTALLMENT,
    element: <InstallmentItem/>
  },
  {
    path: ROUTES.DEPOSIT_ADD,
    element: <DepositAdd/>
  },
  {
    path: ROUTES.MORTGAGE_ADD,
    element: <MortgageAdd/>
  },
  {
    path: ROUTES.PIGGY_ADD,
    element: <PiggyAdd/>
  },
  /// FORM //
  {
    path: ROUTES.DEPOSIT_FORM,
    element: <DepositForm/>
  },
  {
    path: ROUTES.MORTGAGE_FORM,
    element: <MortgageForm/>
  },
  {
    path: ROUTES.PIGGY_FORM,
    element: <PiggyForm/>
  },
  {
    path: ROUTES.PROFILE,
    element: <Profile/>
  },
  {
    path: ROUTES.SADAKKA,
    element: <Sadakka/>
  },
  {
    path: ROUTES.SADAKKA_ITEM,
    element: <SadakkaItem/>
  },
  {
    path: ROUTES.REPLENISHMENT,
    element: <Replenishment/>
  },
  {path: ROUTES.REPLENISHMENT_CARD,
    element: <ReplenishmentCard/>
  },
  {path: ROUTES.REPLENISHMENT_ADD_CARD,
    element: <ReplenishmentAddCard/>
  },
  {path: ROUTES.WITHDRAW,
    element: <Withdraw/>
  },
  {path: ROUTES.TRANSFER,
    element: <Transfer/>
  },
  {path: ROUTES.TRANSFER_INTERNATIONAL,
    element: <TransferInternational/>
  },
  {path: ROUTES.TRANSFER_RK,
    element: <TransferRK/>
  },
  {path: ROUTES.TRANSFER_PROPORTUNITY,
    element: <TransferProportunity/>
  },
  {path: ROUTES.TRANSFER_SELF,
    element: <TransferSelf/>
  }

]);
